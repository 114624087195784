<template>
  <div class="datePicker">
    <span class="material-input-bar" />
    <label class="material-label">
      <slot />
    </label>
    <br v-if="$slots['default']" />
    <el-date-picker
      v-if="!disabled"
      ref="datepicker"
      v-model="currentValue"
      size="small"
      :type="type"
      :format="format"
      :name="name"
      :placeholder="fillPlaceHolder"
      :readonly="readonly"
      :required="required"
      :picker-options="weekPickerOptions"
      @keydown.native="onKeyDown"
      @input="handleModelInput"
      @blur="handleBlur"
    />
    <span v-if="disabled" class="datePicker-disabled">{{ valueString }}</span>
  </div>
</template>

<script>
import { removeChildrenWithClassName } from '@/utils';
import { formatDateByElementFormat } from '@/utils/date';
import { autocompleteDate } from './date-input-utils';
import { parseDateInput } from './date-parser';
import { drawWeekNumbers } from './week-utils';

// Credits: http://blog.vishalon.net/index.php/javascript-getting-and-setting-caret-position-in-textarea/
function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}
export default {
  props: {
    disabled: Boolean,
    format: {
      default: 'dd-MM-yyyy',
      type: String
    },
    name: String,
    placeholder: {
      default: '',
      type: String
    },
    readonly: Boolean,
    required: {
      default: true,
      type: Boolean
    },
    value: [String, Number],
    type: {
      type: String,
      default: 'date'
    },
    showWeekNumbers: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentValue: this.value,
      pendingUserInput: '',
      picker: null
    };
  },
  computed: {
    fillPlaceHolder() {
      return this.currentValue ? '' : this.placeholder;
    },
    valueString() {
      const dt = new Date(this.currentValue).toString();
      return this.currentValue && formatDateByElementFormat(dt, this.format);
    },
    weekPickerOptions() {
      return {
        firstDayOfWeek: 1
      };
    }
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
      if (newValue && typeof newValue === 'string') {
        const parsedValue = this.handleModelInput(newValue);
        this.$emit('init', parsedValue);
      }
    }
  },
  mounted() {
    if (this.showWeekNumbers) {
      this.$watch('$refs.datepicker.pickerVisible', this.handlePickerVisible);
    }
  },
  methods: {
    handleDrawWeekNumbers() {
      if (!this.picker) {
        return;
      }
      const className = 'date-picker-week-table';
      const panel = this.picker.$el.querySelector('.el-picker-panel__content');

      removeChildrenWithClassName(panel, className);
      if (this.picker.currentView !== 'date') {
        return;
      }
      drawWeekNumbers(panel, this.picker.date);
    },
    handlePickerDateChange(panel, date) {
      this.handleDrawWeekNumbers();
    },
    handlePickerCurrentViewChange(type) {
      this.handleDrawWeekNumbers();
    },
    handlePickerVisible() {
      const { picker } = this.$refs.datepicker;
      if (!picker) {
        return;
      }
      if (!this.picker || this.picker._uid !== picker._uid) {
        this.picker = picker;
        picker.$watch('date', this.handlePickerDateChange);
        picker.$watch('currentView', this.handlePickerCurrentViewChange);
      }
    },
    onKeyDown(e) {
      const { $refs } = this;
      const Text = $refs.datepicker.userInput || $refs.datepicker.displayValue || '';
      const parsed = parseDateInput(e, Text);
      if (parsed) {
        this.forceInputText(e.target, parsed.Text, parsed.SelStart);
        this.pendingUserInput = parsed.Text;
      }
    },
    forceInputText(inputEl, text, caretPosition) {
      this.$refs.datepicker.userInput = text;
      this.$nextTick(_ => {
        setCaretPosition(inputEl, caretPosition);
      });
    },
    setSoftFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();

        this.$nextTick(() => {
          this.$refs.datepicker.hidePicker();
        });
      }
    },
    setFocus() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.focus();
      }
    },
    focus() {
      if (this.value) {
        this.setSoftFocus();
      } else {
        this.setFocus();
      }
    },
    handleBlur(e) {
      if (this.pendingUserInput) {
        const newValue = autocompleteDate(this.pendingUserInput);
        this.pendingUserInput = '';
        if (newValue) {
          this.$nextTick(_ => {
            this.handleModelInput(newValue);
          });
        }
      }
    },
    handleModelInput(event) {
      const value = event && Date.parse(event);
      this.$emit('input', value);
      this.$emit('change', value);
      return value;
    }
  }
};
</script>

<style scoped>
.datePicker-disabled {
  margin-top: 5px;
  display: block;
}
</style>

<style>
.material-label {
  color: #9e9e9e;
}
</style>
<style lang="scss">
.date-picker-week-table {
  font-size: 12px;
  table-layout: fixed;
  user-select: none;
  width: 40px;
  border-spacing: 0;
  border-collapse: collapse;
  display: inline;
  font-weight: 700;
  th {
    padding: 5px;
    border-bottom: solid 1px #ebeef5;
  }

  td {
    width: 32px;
    height: 30px;
    padding: 4px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    position: relative;
  }
  th,
  td {
    font-style: italic;
    color: #a2a2a2;
    background-color: #fafafa;
    border-right: solid 1px #ebeef5;
  }
}
.el-date-picker {
  .date-picker-week-table + .el-date-table {
    display: inline;

    th {
      padding: 5px 10px;
    }
  }
}
</style>
