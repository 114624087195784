import { isGiven } from '@/utils';

export function isvalidUsername(str) {
  const validMap = ['admin', 'editor'];
  return validMap.indexOf(str.trim()) >= 0;
}

export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

export function validateLowerCase(str) {
  const reg = /[a-z].*/g;
  return reg.test(str);
}

export function validateUpperCase(str) {
  const reg = /[A-Z].*/g;
  return reg.test(str);
}

export function validateDigit(str) {
  const reg = /[0-9].*/g;
  return reg.test(str);
}
export function validateNonAlphanumeric(str) {
  const reg = /\W.*/g;
  return reg.test(str);
}

export function validateAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

export function isNumber(str) {
  const reg = /^\d+$/;
  return reg.test(str);
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePasswordWithPolicy(value, passwordPolicy) {
  return {
    alphanumeric: (passwordPolicy && !passwordPolicy.requireNonAlphanumeric) || validateNonAlphanumeric(value),
    digit: (passwordPolicy && !passwordPolicy.requireDigit) || validateDigit(value),
    isFromDatabase: passwordPolicy && passwordPolicy.isFromDatabase,
    length: value && value.length >= passwordPolicy.minimumLength,
    lowercase: (passwordPolicy && !passwordPolicy.requireLowercase) || validateLowerCase(value),
    uppercase: (passwordPolicy && !passwordPolicy.requireUppercase) || validateUpperCase(value)
  };
}

export function validateOptionalAddress(value) {
  if (
    value &&
    (isGiven(value.streetName) || isGiven(value.number) || isGiven(value.postalCode) || isGiven(value.city))
  ) {
    return validateAddress(value);
  }
  return true;
}

export function validateAddress({ streetName, number, postalCode, city, countryID }) {
  if (isGiven(number) && isGiven(postalCode)) {
    return true;
  }
  if (isGiven(streetName) && isGiven(number) && isGiven(city)) {
    return true;
  }
  return false;
}

export function validateEAN(ean) {
  if (ean.length !== 13) {
    return false;
  }
  if (!validateDigit(ean)) {
    return false;
  }
  let x = 0;
  let y = 0;
  for (let i = 0; i < 6; i++) {
    x += Number(ean.charAt((i << 1) + 1));
    y += Number(ean.charAt(i << 1));
  }
  const sum = x * 3 + y;
  const checkDigit = (10 - (sum % 10)) % 10;
  return Number(ean.charAt(12)) === checkDigit;
}
