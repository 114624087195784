import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token';

export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
  // return Cookies.set(TokenKey, token, { expires: 1 });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query

  let searchParams = new URLSearchParams(location.search);

  if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
    return true;
  }

  // response_mode: fragment

  searchParams = new URLSearchParams(location.hash.replace('#', '?'));

  if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
    return true;
  }

  return false;
};
