import i18n from '@/i18n';
import { isGiven } from '@/utils';
import { validateEmail } from '@/utils/validate';

export function validatorEmail(rule, value, callback) {
  if (value && !validateEmail(value)) {
    callback(new Error(i18n.t('validation.email')));
  } else {
    callback();
  }
}
export function validatorRequiredObject(rule, value, callback) {
  if (Object.keys(value) === 0 || !Object.values(value).some(v => v > 0)) {
    callback(new Error(i18n.t('validation.required')));
  } else {
    callback();
  }
}
export function validatorRequireNumber(rule, value, callback) {
  if ((value !== 0 && !value) || value.length === 0) {
    callback(new Error(i18n.t('validation.required')));
  } else {
    callback();
  }
}
export const validatorRequireNumberConditional = (condition, errorMessage) => (rule, value, callback) => {
  if (condition && ((value !== 0 && !value) || value.length === 0)) {
    callback(new Error(errorMessage || i18n.t('validation.required')));
  } else {
    callback();
  }
};
export const validatorMaxNumber = (max, errorMessage) => (rule, value, callback) => {
  if (value > max) {
    callback(new Error(errorMessage || i18n.t('validation.exceedMax')));
  } else {
    callback();
  }
};
export function validatorRequire(rule, value, callback) {
  if (!value || value.length === 0) {
    callback(new Error(i18n.t('validation.required')));
  } else {
    callback();
  }
}
export function validatorRequireCustom(isValid) {
  return (rule, value, callback) => {
    if (!isValid(value)) {
      callback(new Error(i18n.t('validation.required')));
    } else {
      callback();
    }
  };
}
export function validatorRequiredRating(rule, value, callback) {
  if (!(value >= 1)) {
    callback(new Error(i18n.t('validation.complexity')));
  } else {
    callback();
  }
}

export function validatorRequireMultipleSelect(rule, value, callback) {
  if (value.length === 0) {
    callback(new Error(i18n.t('validation.required')));
  } else {
    callback();
  }
}

export const validatorUnique = (existingValues, errorMessage) => (rule, value, callback) => {
  if (existingValues.some(excluded => excluded === value)) {
    callback(new Error(i18n.t(errorMessage || 'validation.unique')));
  } else {
    callback();
  }
};
export const validatorConditionalRequired = (conditionalValue, errorMessage) => (rule, value, callback) => {
  if (isGiven(conditionalValue) && !isGiven(value)) {
    callback(new Error(i18n.t(errorMessage || 'validation.required')));
  } else {
    callback();
  }
};
