function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['documentTypeID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['documentTypeID']
  };
  delete item['documentTypeID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {},
  getters: {},
  mutation: {},
  state: {}
};
