function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['dossierID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['dossierID'],
    name: dto['dossierID']
  };
  delete item['dossierID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    dossierInfoById({ dispatch }, dossierId) {
      const payload = {
        query: { DossierID: dossierId }
      };
      return dispatch('fetchlist', payload).then(async response => {
        const items = [];
        for (const dto of response.data.items) {
          const item = await dispatch('transformDto', dto);
          items.push(item);
        }
        return items;
      });
    }
  },
  getters: {
    getDossierInfoById: state => dossierId => {
      const dossier = state.dossierInfoById[dossierId];
      return dossier || [];
    }
  },
  mutation: {},
  state: {
    dossierInfoById: {}
  }
};
