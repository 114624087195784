// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */
export default {
  accesslog: {
    accessedAt: 'Accessed at',
    accessedBy: 'Accessed by',
    accessedResource: 'Accessed resource',
    entity: 'Entity',
    entityID: 'ID',
    ip: 'IP',
    parameters: 'Parameters'
  },
  autoUpdate: {
    alertConfirm: 'Reload page',
    alertMessage: 'There is a new update available for this application. Please reload the page to update.',
    alertTitle: 'Update'
  },
  common: {
    actions: 'Actions',
    actor: 'Actor',
    add: 'Add',
    address: 'Address',
    adres: 'Address',
    archive: 'Archive',
    billingAddress: 'Billing address',
    block_notification_not_saved_warning: 'Notification setting not saved. Please try again later.',
    browserNotSupported: 'Your browser does not support this feature',
    cancel: 'Cancel',
    city: 'City',
    clearFilters: 'Clear all filters',
    close: 'Close window',
    close_unsaved_warning: 'You have unsaved changes. Are you sure you want to close this window?',
    close_warning: 'Are you sure you want to close this screen?',
    code: 'Code',
    companyAddress: 'Company address',
    confirm: 'Confirm',
    contractType: 'Contracttype',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    copyToClipboardFail: 'Copy to clipboard failed',
    copyToClipboardSuccess: 'Copied to clipboard',
    country: 'Country',
    data_created: 'The data has been saved',
    data_save_error: 'The data could not be be saved',
    data_updated: 'The changes are saved',
    date: 'Date',
    dateOfBirth: 'Date of birth',
    decimals: 'Decimals',
    defaultComment: 'Default comment',
    delete: 'Delete',
    delete_cancelled: 'Delete cancelled',
    delete_completed: 'Delete completed',
    delete_record_summary_warning: 'Are you sure you want to delete <b>{summary}</b>?',
    delete_record_warning: 'Are you sure you want to delete this?',
    department: 'Department',
    description: 'Description',
    documentType: 'Document type',
    documentTypeName: 'Document type',
    documents: 'Documents',
    dontSave: "Don't save",
    dontSend: "Don't send",
    dont_show_again_warning: 'Never ask again',
    dossierID: 'Dossier id',
    draft: 'Draft',
    ean: 'EAN-13',
    edit: 'Edit',
    email: 'Email address',
    emailPartner: 'Email address partner',
    emptyString: ' ',
    error: 'Error',
    export: 'Export',
    file: 'File',
    filename: 'Filename',
    firstName: 'First name',
    gender: 'Gender',
    genderPrefix: 'Prefix',
    genderSalutation: 'Salutation',
    general: 'General',
    id: 'ID',
    inactive: 'Inactive',
    initials: 'Initials',
    jobFunction: 'Job function',
    lastName: 'Last name',
    leave_mail_warning: 'You are about to leave this page. Do you want to send the email before leaving?',
    leave_unsaved_warning: 'You are about to leave this page. Do you want to save changes',
    leningen: 'Loans',
    loading: 'Loading ...',
    mailingAddress: 'Mailing address',
    middleName: 'Middle name',
    mobile: 'Mobile phone',
    modified: 'Modified',
    name: 'Name',
    no: 'No',
    noData: 'No data',
    notApplicable: 'Not applicable',
    ok: 'OK',
    password: 'Password',
    plaats: 'City',
    postalCode: 'Postal code',
    postcode: 'Postal code',
    prefix: 'Prefix',
    publish: 'Publish',
    quantity: 'Quantity',
    question: 'Question',
    readMore: 'Read more ...',
    reference: 'Reference',
    remark: 'Remark',
    remarks: 'Remarks',
    save: 'Save',
    saveClose: 'Save & close',
    saveNext: 'Save & next',
    search: 'Search',
    send: 'Send',
    skip: 'Skip',
    status: 'Status',
    stay: 'Stay on page',
    streetName: 'Street',
    streetNumber: 'Number',
    success: 'Success',
    switchOff: 'Off',
    switchOn: 'On',
    taskstatus: 'Task status',
    telephone: 'Telephone',
    title: 'Title',
    tussenvoegselLener: 'Middle name',
    tussenvoegselPartner: 'Middle name',
    type: 'Type',
    unit: 'Unit',
    username: 'Username',
    vatNumber: 'VAT number',
    voorlettersLener: 'Initials',
    voorlettersPartner: 'Initials',
    warning: 'Warning',
    workInfo: 'Work information',
    yes: 'Yes'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Valid from',
    value: 'Value'
  },
  divider: {
    adresgegevens: 'ADDRESSDATA',
    contactgegevens: 'CONTACTDATA',
    lener: 'BORROWER',
    overigegegevens: 'OTHER DATA',
    partner: 'PARTNER'
  },
  document: {
    approved: 'Approved',
    contentDate: 'Date content',
    contents: 'Contents',
    createDate: 'Date created',
    description: 'Description',
    documentType: 'Document type',
    dossierID: 'Dossier nr',
    rejectReason: 'Reason rejection',
    rejected: 'Rejected',
    status: 'Status'
  },
  dossier: {
    applicant: 'Applicant',
    archiveDate: 'Date archived',
    bcCode: 'BC code',
    company: 'Works at',
    datumNaarBestuurTb: 'Date to board Tb',
    datumNaarBestuurTk: 'Date to board Tk',
    debtstep: 'Debtladder step',
    delay: 'Delay',
    dossierID: 'Dossiernumber',
    dossierNummer: 'Dossiernumber',
    enddateContract: 'Contract until',
    function: 'Function',
    namePartner: 'Name partner',
    personeelsNummer: 'Personnel nr',
    reason: 'Reason',
    remarks: 'Additional information',
    salaryscale: 'Scalenumber',
    status: 'Status',
    uniekpersoneelsNummer: 'Unique Personelnumber'
  },
  dossierAction: {
    actingRoleName: 'Acting role',
    actionStatusName: 'Action status',
    actionTypeName: 'Action type',
    alertDate: 'Alert date',
    comment: 'Comment',
    creationDate: 'Creation date'
  },
  editor: {
    entityFetchError: 'This item does not exist (anymore) or you do not have the permission to view this page.'
  },
  entity: {
    DossierAction: 'Action'
  },
  error: {
    general_request: 'Something went wrong. Try again later.',
    oneOrMoreInputs: 'Data could not be saved because there are errors in the form',
    request_401: 'You are not authorised',
    request_403: 'You do not have permission to perform this action',
    save: 'Something went wrong. Try again later.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Back to home',
    headline401: "You don't have permission to go to this page",
    headline403: "You don't have permission to go to this page",
    headline404: 'Page not found',
    message401: 'If you do not agree with this, please contact your administrator.',
    message403: 'If you do not agree with this, please contact your administrator.',
    message404: 'Please check that the URL you entered is correct.',
    return: 'return',
    title401: 'Oops',
    title403: 'Access denied',
    title404: 'Sorry'
  },
  eventlog: {
    emptyException: 'No exception',
    emptyMessage: 'No message',
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Occurred at',
    thread: 'Thread'
  },
  fileExport: {
    generate: 'Upload',
    invoiceFileName: 'Invoices File',
    surchargeFileName: 'Surcharges file'
  },
  fileUpload: {
    dragAndDropHtml: 'Drag an import file here',
    fileName: 'File name',
    uploadTip: 'To import a file use drag and drop or click the upload logo to select a file.'
  },
  forgotPassword: {
    description:
      'You can change your password for security reasons or reset it if you forget it. Complete the form below to get the instructions sent to your email',
    done: 'An email has been sent to {email}. Please check your email for instructions.',
    submit: 'Send email',
    title: 'Forgot password'
  },
  home: {
    welcomeVisitor: 'Welcome visitor'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  loan: {
    actNumber: 'Actnumber',
    amount: 'Amount',
    applicant: 'Request by',
    bKRCheck: 'BKR check',
    bankstatements: 'Bankstatements',
    calculateTerms: 'Calculate terms',
    calculateThl: 'Calculate Thl date',
    debtSecurities: 'Debt Securities',
    debtstep: 'Debtladder',
    delay: 'Delay',
    delayDate: 'Date delay',
    dossierID: 'Dossiernumber',
    explanationApplicant: 'Explanation applicant',
    firstDate: 'First redemption date ',
    intake: 'intake',
    interest: 'Interest',
    interview: 'Interview',
    loanID: 'Actnumber',
    loanType: 'Loantype',
    payslips: 'Payslips',
    plan: 'Plan',
    reason: 'Reason',
    reasonRemark: 'Reason extra',
    remark: 'Remarks',
    repaymentAmount: 'Repaymentamount',
    requestDate: 'Request date',
    requestStatus: 'Request status',
    requirements: 'Requirements',
    status: 'Status',
    terms: 'Terms',
    thlDate: 'Thl date'
  },
  login: {
    code: 'User name',
    forbidden: 'User/IP combination has been blocked.',
    logIn: 'Log in',
    logInWithAnotherAccount: 'Sign in with another account',
    password: 'Password',
    title: 'Log in',
    unauthorised: 'Login information is wrong',
    username: 'Username'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Body',
    cc: 'Cc',
    error: 'An error has occured. Your e-mail was not sent.',
    from: 'From',
    subject: 'Subject',
    success: 'Your e-mail has been sent successfully.',
    to: 'To'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Language',
    logOut: 'Log Out',
    screenfull: 'Full screen',
    size: 'Text size',
    theme: 'Theme'
  },
  newPassword: {
    description: 'You can now permanently change your password.',
    done: 'Your password has successfully been changed.',
    invalidToken: 'This link has expired.',
    submit: 'Change password',
    title: 'New password'
  },
  permission: {
    AccessLog: 'Access log',
    ActionStatus: 'Action status',
    ActionType: 'Action types',
    Applicant: 'Applicants',
    BCcode: 'BC codes',
    Company: 'Companies',
    Configuration: 'Settings',
    Country: 'Countries',
    DebtStep: 'Debt ladder',
    Delay: 'Delays',
    Document: 'Documents',
    DocumentStatus: 'Document statuses',
    DocumentType: 'Document types',
    Domain: 'Domains',
    Dossier: 'Dossiers',
    DossierAction: 'Actions',
    EventLog: 'Event log',
    Function: 'Functions',
    Gender: 'Genders',
    Language: 'Languages',
    Loan: 'Loans',
    LoanType: 'Loan types',
    Option: 'Options',
    Permission: 'Permissions',
    Reason: 'Reasons',
    Report: 'Reports',
    Role: 'Roles',
    Status: 'Statuses',
    Template: 'Templates',
    Translation: 'Translations',
    User: 'Users',
    execute: 'Execute',
    none: 'None',
    pageIsReadonly: 'You are not allowed to edit this page.',
    read: 'Read',
    roles: 'Your roles',
    selectPlaceholder: 'Select the access level',
    switchRoles: 'Switch roles',
    tabPermissions: 'Permissions',
    tabTaskTypes: 'Task Types',
    write: 'Write'
  },
  pickrec: {
    chooseEntryFor: 'Choose "{caption}"',
    itemNotFound: "'{caption}' not found",
    multiSearchFiltersLabel: 'Filters',
    suggestionsMayBeMore: 'There are possibly more search hits',
    suggestionsRestCount: 'There are {count} more search hits'
  },
  report: {
    dateApplicationBKR: 'Application BKR',
    dateAuthorizationDocumentReceived: 'Act authorization received',
    dateAuthorizationDocumentSent: 'Act authorization sent',
    dateAuthorizationHR: 'Authorization to HR',
    dateBoardApproved: 'Approved board',
    dateBoardDenied: 'Denied board',
    dateBoardRevoked: 'Revoked board',
    dateCancelationBKR: 'Cancelled BKR',
    dateFirstPayment: 'First payment',
    dateIrrecoverableBKR: 'Irrevocable BKR',
    dateMandateApproved: 'Mandate approved',
    dateMandateDenied: 'Mandate denied',
    datePayed: 'Payed',
    dateRedemptionBKR: 'Redemption BKR',
    dateRedemptionEstimateBKR: 'Redemtion BKR (estimate)',
    dateToBoardDateTb: 'To board Tb',
    dateToBoardDateTk: 'To board Tk'
  },
  request: {
    networkError: 'Network can not be reached.'
  },
  requestActions: {
    generate: 'Generate actions',
    loanPlaceholder: 'Select loan'
  },
  requestDocument: {
    generate: 'Generate documents',
    loanPlaceholder: 'Select loan',
    templatePlaceholder: 'Select template'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createActionStatus: 'Create a action status',
    createActionType: 'Create a actiontype',
    createApplicant: 'Create a request by',
    createBCcode: 'Create a BC code',
    createCompany: 'Create a company',
    createCountry: 'Create country',
    createDebtStep: 'Create a debtstep',
    createDelay: 'Create a delay',
    createDocument: 'Create a document',
    createDocumentStatus: 'Create a documentstatus',
    createDocumentType: 'Create a documenttype',
    createDossier: 'Create a dossier',
    createDossierAction: 'Create a action',
    createFunction: 'Create a function',
    createGender: 'Create a gender',
    createLanguage: 'Create language',
    createLoan: 'Create a loan',
    createLoanType: 'Create a loantype',
    createReason: 'Create a reason',
    createRole: 'Create a role',
    createStatus: 'Create a status',
    createTemplate: 'Create a template',
    createUser: 'Create a user',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editActionStatus: 'Edit action status',
    editActionType: 'Edit actiontype',
    editApplicant: 'Edit request by',
    editBCcode: 'Edit BC code',
    editCompany: 'Edit company',
    editConfiguration: 'Edit setting',
    editCountry: 'Edit country',
    editDebtStep: 'Edit debtstep',
    editDelay: 'Edit delay',
    editDocument: 'Edit document',
    editDocumentStatus: 'Edit documentstatus',
    editDocumentType: 'Edit documenttype',
    editDossier: 'Edit dossier',
    editDossierAction: 'Edit action',
    editFunction: 'Edit function',
    editGender: 'Edit gender',
    editLanguage: 'Edit language',
    editLoan: 'Edit loan',
    editLoanType: 'Edit loantype',
    editReason: 'Edit reason',
    editRole: 'Edit role',
    editStatus: 'Edit status',
    editTemplate: 'Edit template',
    editTranslation: 'Edit translation',
    editUser: 'Edit user',
    editdebtStep: 'Edit debtstep',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_action_status: 'Action Status',
    menu_action_types: 'Action Types',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Application',
    menu_admin_company_records: 'Company records',
    menu_admin_configuration: 'Settings',
    menu_admin_countries: 'Countries',
    menu_admin_eventlog: 'Event Log',
    menu_admin_languages: 'Languages',
    menu_admin_options: 'Options lists',
    menu_admin_roles: 'Roles',
    menu_admin_security: 'Roles and users',
    menu_admin_translations: 'Translation',
    menu_admin_users: 'Users',
    menu_applicants: 'Request by',
    menu_bccodes: 'BC codes',
    menu_companies: 'Works at',
    menu_debt_ladder: 'DebtLadder',
    menu_delays: 'Delays',
    menu_document_statuses: 'DocumentStatuses',
    menu_document_types: 'DocumentTypes',
    menu_documents: 'Documents',
    menu_dossier_actions: 'Actions',
    menu_dossiers: 'Dossiers',
    menu_functions: 'Functions',
    menu_genders: 'Genders',
    menu_loan_types: 'LoanTypes',
    menu_loans: 'Loans',
    menu_loansystem: 'Loansystem',
    menu_reasons: 'Reasons',
    menu_reportActions: 'Action report',
    menu_reportDossiersNaarBestuurTb: 'Dossiers to board Tb',
    menu_reportDossiersNaarBestuurTk: 'Dossiers to board Tk',
    menu_reports: 'Reports',
    menu_reporttotaleLeningenGiften: 'Total loans and gifts',
    menu_statuses: 'Statuses',
    menu_templates: 'Templates',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'User settings',
    zip: 'Zip'
  },
  size: {
    medium: 'Medium',
    mini: 'Mini',
    small: 'Small'
  },
  stringInsert: {
    btnText: 'Insert...',
    closeOnInsert: 'Close dialog after inser',
    textIntro: 'Click on a text to insert.',
    textIntroUnavailable: 'There is nothing to insert.',
    textTitle: 'Insert text',
    toastInsert: 'Inserted: {name}'
  },
  table: {
    actions: 'Actions',
    searchPlaceholder: 'Type to search',
    searchSelectPlaceholder: 'Select to search'
  },
  tabs: {
    acties: 'Actions',
    adres: 'Address',
    documenten: 'Documents',
    lener: 'Borrower',
    leningen: 'Leningen',
    overig: 'General',
    partner: 'Partner'
  },
  tagsView: {
    close: 'Close',
    closeAll: 'Close All',
    closeOthers: 'Close Others',
    refresh: 'Refresh'
  },
  textArea: {
    placeholder: 'Your text'
  },
  translation: {
    code: 'Translation code'
  },
  user: {
    avatar: 'Profile picture',
    avatarInfo: 'URL to profile picture',
    newPassword: 'New password',
    newPasswordInfo: "Leave empty when you don't want to change your password",
    oldPassword: 'Current password',
    oldPasswordInfo: 'Enter your current password for verification',
    oldPasswordInvalid: 'You did not enter the correct current password',
    passwordChanged: 'Your password has been changed',
    repeatPassword: 'Repeat password',
    repeatPasswordInfo: 'Repeat your new password for verification',
    roles: 'Roles'
  },
  validation: {
    complexity: 'Choose complexity',
    ean: 'This barcode is incorrect',
    email: 'This is an invalid e-mail address',
    error: {
      alphanumeric: 'Password must contain a symbol',
      digit: 'Password must contain a digit',
      isFromDatabase: 'Could not validate your password, because the server could not be reached.',
      itemNotFound: 'Item not found.',
      length: 'Password must contain at least {count} characters',
      lowercase: 'Password must contain a lowercase character',
      passwordComplexity: 'The password does not meet the required complexity',
      uppercase: 'Password must contain an uppercase character'
    },
    exceedMax: 'This value is too high',
    formValidationFailed: 'This form has errors. Please review your input and retry',
    numeric: 'Only numbers allowed',
    oldPasswordRequired: 'Please input the old password',
    repeatPasswordMismatch: "Two inputs don't match",
    required: 'This field is required',
    unique: 'This value must be unique'
  },
  wizard: {
    next: 'Next',
    prev: 'Back',
    skip: 'Skip'
  }
};
