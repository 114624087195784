import request from '@/utils/request';

const downloadDocumentRequest = fileName => request.get(`/template/content/${fileName}`, { responseType: 'blob' });

function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['templateID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['templateID']
  };
  delete item['templateID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    downloadTemplate({ dispatch }, fileName) {
      return downloadDocumentRequest(fileName);
    }
  },
  getters: {},
  mutation: {},
  state: {}
};
