import Vue from 'vue';
import { UserManager, User, Log, UserManagerSettings } from 'oidc-client-ts';

export default {
  install(vue: typeof Vue, oidcSettings: UserManagerSettings): void {
    Log.setLogger(console);

    const oidcUserManager = new UserManager(oidcSettings);
    // handle events

    oidcUserManager.events.addAccessTokenExpiring(function() {
      console.log('access token expiring');
    });

    oidcUserManager.events.addAccessTokenExpired(function() {
      console.log('access token expired');
    });

    oidcUserManager.events.addSilentRenewError(function(err: Error) {
      console.error('silent renew error', err);
    });

    oidcUserManager.events.addUserLoaded(function(user: User) {
      console.log('user loaded', user);
    });

    oidcUserManager.events.addUserUnloaded(function() {
      console.log('user unloaded');
    });

    oidcUserManager.events.addUserSignedOut(function() {
      console.log('user signed out');
    });

    oidcUserManager.events.addUserSessionChanged(function() {
      console.log('user session changed');
    });

    vue.prototype.$oidc = oidcUserManager;
  }
};
