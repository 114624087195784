import request from '@/utils/request';
const apiRequestActions = actionsRequest => {
  return request({
    data: { ...actionsRequest },

    method: 'post',

    url: '/dossier-action/requests'
  });
};

function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['dossierActionID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['dossierActionID']
  };
  delete item['dossierActionID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    requestActions({ dispatch }, actionsRequest) {
      return apiRequestActions(actionsRequest);
    }
  },
  getters: {},
  mutations: {},
  state: {}
};
