import Vue from 'vue';
import VueI18n from 'vue-i18n';
import nlElementUiMessages from 'element-ui/lib/locale/lang/nl';
import enElementUiMessages from 'element-ui/lib/locale/lang/en';
import nlAppMessages from './nl';
import enAppMessages from './en';

Vue.use(VueI18n);

const messages = {
  en: {
    ...enElementUiMessages,
    ...enAppMessages
  },
  nl: {
    ...nlElementUiMessages,
    ...nlAppMessages
  }
};

export default new VueI18n({
  messages
});
