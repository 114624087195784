import request from '@/utils/request';
const downloadDocumentRequest = id => request.get(`/document/content/${id}`, { responseType: 'blob' });
const deleteDocumentRequest = id => request.delete(`/document/content/${id}`);

const apiRequestDocument = documentRequest => {
  return request({
    data: { ...documentRequest },

    method: 'post',

    url: '/document/request'
  });
};
const apiRequestDocuments = documentsRequest => {
  return request({
    data: { ...documentsRequest },

    method: 'post',

    url: '/document/requests'
  });
};
function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['documentID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['documentID']
  };
  delete item['documentID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    downloadDocument({ dispatch }, id) {
      return downloadDocumentRequest(id);
    },
    deleteDocument({ dispatch }, id) {
      return deleteDocumentRequest(id);
    },

    requestDocument({ dispatch }, documentRequest) {
      return apiRequestDocument(documentRequest);
    },
    requestDocuments({ dispatch }, documentsRequest) {
      return apiRequestDocuments(documentsRequest);
    }
  },
  getters: {},
  mutation: {},
  state: {}
};
